const _ = {
  find: require('lodash/find'),
  pick: require('lodash/pick'),
};

import * as React from 'react';
import classnames from 'classnames';
import { Link, RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import { Mutation, MutationFn } from 'react-apollo';
import Helmet from 'react-helmet';

import { routes } from 'inkp-routes/public';
import Input from 'inkp-components/dist/Components/Input';
import Button from 'inkp-components/dist/Components/Button';
import { validateSignInParams } from 'inkp-user-sdk/user';

import { StateContainer, Subscribe } from 'infra-frontend/helpers/apollo';

import AccountLayout from '../../layout/account';
import { findServiceError } from '../../util/apollo';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

const FORGOT_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

class ForgotState extends StateContainer {
  initialState = {
    fields: {
      email: '',
    },
    errors: [],
    forgotError: '',
    sent: false,
  };

  shape = `
    {
      fields {
        email
      }
      errors {
        valid
        message
        field
      }
      forgotError
      sent
    }
  `;

  // Defining event handler to update the state
  updateField = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if (field === 'email') {
      value = value.toLowerCase();
    }
    
    const fields = Object.assign({}, this.state.fields, { [field]: value });

    this.setState({ fields });
  };

  setErrors = (errors: any) => {
    this.setState({ errors, forgotError: '' });
  };

  setForgotError = (error: string) => {
    this.setState({ forgotError: error });
  };

  setSent = () => () => {
    this.setState({ sent: true });
  };
}

export default class ForgotPasswordPage extends React.Component<RouteComponentProps> {
  submit = (state: any, setErrors: any, forgot: any) => (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    forgot({
      variables: _.pick(state.fields, 'email'),
    });
  };

  forgotError = (setErrors: any, setForgotError: any) => (error: any) => {
    const serviceError = findServiceError(error);
    if (serviceError && serviceError.extensions && serviceError.extensions.code === 'USER_NOT_FOUND') {
      return setErrors([
        { valid: false, field: 'email', message: 'Sorry, there’s no account associated with that email address' },
      ]);
    }
    setForgotError('Error encountered');
  };

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    return (
      <Subscribe to={[ForgotState]} namespace="Forgot">
        {({ state, updateField, setErrors, setForgotError, setSent }) => (
          <Mutation
            mutation={FORGOT_MUTATION}
            onCompleted={setSent()}
            onError={this.forgotError(setErrors, setForgotError)}
          >
            {(forgot: any) => {
              const emailError = _.find(state.errors, { field: 'email' });
              return (
                <AccountLayout>
                  <Helmet>
                    <title>Inkpop - Forgot Password</title>
                  </Helmet>
                  {!state.sent && (
                    <div>
                      <div>
                        <h1 className="fs-xl color-coral fw-extra-bold">Forgot Password</h1>
                      </div>
                      <div className="mt-1">Enter your email and we'll send you a link to reset your password.</div>
                      <form className="mt-2 w-full" onSubmit={this.submit(state, setErrors, forgot)}>
                        <Input
                          label="Email"
                          className="w-full mt-1p5"
                          inputType="email"
                          defaultValue={state.fields.email}
                          onChange={updateField('email')}
                          error={emailError && !emailError.valid}
                          helper={emailError ? emailError.message : state.forgotError}
                          helperColor={(emailError && !emailError.valid) || state.forgotError ? 'red' : 'green'}
                        />
                        <Button buttonType="submit" className="w-full mt-1p5">
                          Submit
                        </Button>
                      </form>
                    </div>
                  )}
                  {state.sent && (
                    <div>
                      <div>
                        <div className="fs-xl color-coral fw-extra-bold">Email Sent</div>
                      </div>
                      <div className="mt-1">
                        Email has been sent to <u>{state.fields.email}</u>. Check your inbox and use the link to reset
                        your password.
                      </div>
                      <Button className="w-full mt-1p5" link={routes.app.base}>
                        Back to Home
                      </Button>
                    </div>
                  )}
                </AccountLayout>
              );
            }}
          </Mutation>
        )}
      </Subscribe>
    );
  }
}
